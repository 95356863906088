import { template as template_88aaf4430ad340cc8dbbc8bc84f36928 } from "@ember/template-compiler";
const FKControlMenuContainer = template_88aaf4430ad340cc8dbbc8bc84f36928(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;

import { template as template_d3aeb818c2c84973a2f0fd8c4eb70f87 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_d3aeb818c2c84973a2f0fd8c4eb70f87(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;

import { template as template_c82f0ae775914a15a55b3e930bc88a9e } from "@ember/template-compiler";
const SidebarSectionMessage = template_c82f0ae775914a15a55b3e930bc88a9e(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;

import { template as template_a805c4efbc0948c3aa01628f59095964 } from "@ember/template-compiler";
const FKLabel = template_a805c4efbc0948c3aa01628f59095964(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;

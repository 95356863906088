import { template as template_4dfa6edec4f24a7c9ff0fb8687ebd631 } from "@ember/template-compiler";
const FKText = template_4dfa6edec4f24a7c9ff0fb8687ebd631(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
